import React from 'react';
import Nav from '../Nav/Nav';
import hero1 from '../../assets/Svgs/hero1.svg';
import hero3 from '../../assets/Svgs/hero3.svg';

const Hero = () => {
  return (
    <section className='relative mt-16 lg:mt-0'>
      {/* <Nav /> */}
      <figure className='hidden md:block '>
        <img src={hero1} alt='hero' className='object-cover w-full h-full' />
      </figure>

      <figure className='block md:hidden mt-12 md:mt-0'>
        <img src={hero3} alt='hero' className='object-cover w-full h-full' />
      </figure>
      <div className='w-[17.375rem] h-[12.125rem] small:w-[20.375rem] small:h-[13.875rem] md:w-[32.4375rem] md:h-[19.125rem] lg:w-[39.4375rem] lg:h-[22.125rem] xl:w-[49.4375rem] mx-7 md:mx-16 absolute inset-0 xl:h-[25.125rem] border-2 border-white/20 rounded-lg bg-white/30 backdrop-blur-[15px] mt-auto mb-14 md:mb-20 lg:mb-28 xl:mb-48 p-3 small:p-4 md:p-8 xl:p-12'>
        <h1 className='small:text-2xl text-lg md:text-[1.8125rem] lg:text-[2.8125rem] xl:text-[3.8125rem] afacad font-bold text-[#002C54] leading-normal lg:leading-[3.8125rem]'>
          Innovative Solutions for a<br /> Smart and Safe Future
        </h1>
        <p className='leading-normal md:leading-[1.7rem] text-sm pt-[0.75rem] small:pt-[1rem] md:pt-[1.2rem] xl:pt-[1.44rem] md:text-lg pb-[1rem] small:pb-[1.5rem] md:pb-[2rem] font-semibold afacad lg:text-[1.5rem] text-[#002C54]'>
          Your partner in renewable energy, smart
          <br className='block md:hidden' /> automation,
          <br className='hidden md:block' /> and sustainable construction
        </p>

        <button className='bg-[#FFDF53] text-xs small:text-sm font-semibold md:text-base lg:text-lg leading-normal inter text-[#002C54] rounded-full py-3 md:py-[1rem] lg:py-[1.12rem] px-[2.5rem]'>
          Explore Our Services
        </button>
      </div>
    </section>
  );
};

export default Hero;
