import React from 'react';
import Solar from '../../assets/Svgs/Solar.svg';
import Camera from '../../assets/Svgs/Camera.svg';
import mobile from '../../assets/Svgs/mobile.svg';
import icon1 from '../../assets/Svgs/icon1.svg';
import icon2 from '../../assets/Svgs/icon2.svg';
import icon3 from '../../assets/Svgs/icon3.svg';

const Services = () => {
  const services = [
    {
      id: 1,
      img: Solar,
      icon: icon1,
      title: '<span class="text-[#017DC0]">Renewable </span>Solar Solutions',
      description:
        'At Nasmaj Hubs, we specialize in providing clean, sustainable solar energy solutions to homes and businesses. Our solar panel installations are designed to maximize energy efficiency, reduce costs, and lower environmental impact... <span class="text-[#00B5E8]">Read more</span>',
    },
    {
      id: 2,
      icon: icon2,
      title:
        '<span class="text-[#017DC0]">Surveillance & </span>Security Solutions',
      description:
        'Ensuring the safety of your property and loved ones is a top priority. Nasmaj Hubs offers state-of-the-art surveillance and security systems tailored to your specific needs. From residential homes to large business premises, we provide CCTV, alarm... <span class="text-[#00B5E8]">Read more</span>',
      img: Camera,
    },
    {
      id: 3,
      title: '<span class="text-[#017DC0]">Smart Home &</span> Automation',
      icon: icon3,
      description:
        'Imagine a home that works for you. Our smart home solutions make your space more convenient, secure, and energy-efficient. With integrated control systems for lighting, climate, security, and entertainment, we turn your house into a smart hub... <span class="text-[#00B5E8]">Read more</span>',
      img: mobile,
    },
  ];

  return (
    <section className='py-[1rem] md:py-[4.88rem] mb-7 md:mb-0 bg-[#002C54] md:bg-white'>
      <div className=' px-2 mac:max-w-[83rem] mx-auto xl:max-w-[73rem] pro:max-w-[52rem] md:max-w-[45rem] max-w-[22rem] lg:max-w-[59rem]'>
        <h1 className='md:text-[#002C54] text-white md:text-center text-left text-[2rem] md:text-[3rem] font-bold leading-[5.625rem] afacad'>
          Core Services
        </h1>

        <div className='mt-[1.78rem] lg:mt-[3.25rem] flex gap-[7rem] md:gap-[3rem] md:flex-row flex-col md:justify-between h-[100rem] small:h-[103rem] md:h-[25rem] lg:h-[35rem]'>
          {services.map((item, index) => (
            <div key={item.id} className='relative'>
              <img
                src={item.img}
                alt=''
                className={`${index === 1 ? 'mt-[17rem] md:mt-0' : ''} ${
                  index === 2 ? 'mt-[17rem] md:mt-0' : ''
                }`}
              />
              <div
                className={`h-[22.46656rem] ${
                  index === 1 ? 'top-[26rem] small:top-[27rem] md:mt-0' : ''
                } ${
                  index === 2 ? 'top-[26rem] small:top-[27rem] md:mt-0' : ''
                } w-[18.04613rem] small:w-[21.04613rem] md:w-[14.3125rem] md:h-[20.875rem] lg:w-[18.3125rem] lg:h-[28.875rem] xl:w-[22.3125rem] xl:h-[24.875rem] absolute left-0 right-0 mx-auto top-36 small:top-40 md:top-24 lg:top-36 xl:top-44 bg-white shadow-lg px-1 lg:px-2 rounded-tl-[6.25rem] rounded-tr-[6.25rem] rounded-bl-lg rounded-br-lg border-2 border-[#8D8A8A] py-7`}
              >
                <div className='flex flex-col justify-center'>
                  <div>
                    <img
                      src={item.icon}
                      alt='icons'
                      className='xl:w-auto xl:h-auto w-16 h-16 md:w-10 md:h-10 lg:w-16 l:h-16 mx-auto'
                    />
                  </div>
                </div>
                <p
                  className='text-center pt-[0.94rem] pb-[0.88rem] text-xl small:text-2xl md:text-lg text-[#002C54] afacad font-mediumS md:leading-normal lg:text-[1.5rem] xl:text-[1.625rem]'
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <p
                  className='text-[#002C54] px-3 afacad text-base small:text-lg md:text-sm lg:text-[1.1875rem] font-medium md:leading-normal'
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          ))}
        </div>

        <div className='flex justify-center'>
          <button className='w-full md:w-auto md:px-[2.5rem] mt-[5rem] lg:mt-[8rem] inter text-[1.125rem] font-semibold leading-[1rem] py-[1.12rem] bg-[#FFDF53] text-[#002C54] rounded-full'>
            See all Services
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;
