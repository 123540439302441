import './App.css';
// import background from './assets/background.svg';
// import logo from './assets/logo.svg';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Services from './components/Services/Services';
import Why from './components/Why/Why';
import Testimonials from './components/Testimonials/Testimonials';
import Partners from './components/Partners/Partners';
import Footer from './components/Footer/Footer';
import Nav from './components/Nav/Nav';

function App() {
  return (
    <section className='relative overflow-hidden'>
      {/* <div className='absolute inset-0 bg-[#0C5C93] -z-5'></div> */}
      {/* <div className='absolute inset-0 bg-[#002C54]/75 z-10'></div>
      <img
        src={background}
        alt=''
        className='object-cover w-full h-full z-10'
      />
      <div className='absolute inset-0 z-20 flex flex-col justify-center items-center'>
        <img src={logo} alt='logo' className='object-cover' />
        <h2 className='text-white afacad font-semibold text-center pt-[2rem]  text-[2.63644rem] md:text-[4.53644rem] md:leading-[4.7rem] lg:text-[5.53644rem] xl:text-[7.13644rem] lg:leading-[6.46875rem]'>
          Our website is <br /> launching soon
        </h2>

        <p className='pt-[1.5rem] md:pt-[2.1rem] afacad text-white font-normal leading-[5.23288rem] text-[1.25rem] md:text-[2rem] text-center'>
          Stay tuned!
        </p>
      </div>
      <footer className='absolute inset-0 z-20 flex flex-col justify-center items-center'>
        <p className='text-white afacad mt-auto'>
          2024 Nasmaj Hubs Services. All rights reserved{' '}
        </p>
      </footer> */}
      <Nav />
      <Hero />
      <About />
      <Services />
      <Why />
      <Testimonials />
      <Partners />
      <Footer />
    </section>
  );
}

export default App;
